// // See https://pilot.co/blog/implementing-baseline-rhythm-in-css/

// /*
//  * Config
// */

// $line-height: 24px;

// $font-sizes: (
//   s: 13px,
//   m: 15px,
//   l: 19px,
//   xl: 35px,
// );
// $cap-heights: (
//   s: 0.8,
//   m: 0.8,
//   l: 0.68,
//   xl: 0.68,
// );

// // Accepts `s`, `m`, `l`, or `xl`
// @function rhythm-shift($size-name) {
//   $font-size: map-get($font-sizes, $size-name);
//   $cap-height: map-get($cap-heights, $size-name);

//   $offset: ($line-height - $cap-height * $font-size) / 2;
//   @return round($offset);
// }

// /*
//  * Solution 3
// */

// $offset-s: rhythm-shift(s);
// $offset-m: rhythm-shift(m);
// $offset-xl: rhythm-shift(xl);

// .rhythm-s {
//   font-size: map-get($font-sizes, s);
//   margin-top: $offset-s;
//   margin-bottom: -1 * $offset-s;
// }

// .rhythm-m {
//   font-size: map-get($font-sizes, m);
//   margin-top: $offset-m;
//   margin-bottom: -1 * $offset-m;
// }

// .rhythm-xl {
//   font-size: map-get($font-sizes, xl);
//   margin-top: $offset-xl;
//   line-height: 48px;
//   margin-bottom: -1 * $offset-xl;
// }

// /*
//  * Boxes with background
// */

// .background {
//   background: rgba(blue, 0.15);
// }

// .proper-background {
//   background: rgba(blue, 0.15);
//   padding: 0.1px 0;
// }

// /*
//  * Utility classes
// */

// .pt-1 {
//   padding-top: $line-height;
// }

// .pb-1 {
//   padding-bottom: $line-height;
// }

// .mt-1 {
//   margin-top: $line-height;
// }

// .mb-1 {
//   margin-bottom: $line-height;
// }

// /*
//  * Meta
// */

// html {
//   $grid-color: rgba(red, 0.15);
//   background: linear-gradient(
//     $grid-color,
//     $grid-color 1px,
//     transparent 1px
//   );
//   background-size: 1px $line-height;
// }

// body {
//   margin: 0 auto;
//   padding: (2 * $line-height) $line-height;
//   line-height: $line-height;
// }

* {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  font-feature-settings: "kern" 1, "liga" 1;
  min-height: 0vw;
}

html {
  /* background-color: #d4eaf7; */
  color: black;
  font-weight: normal;
  // font-size: 16px;
  // line-height: 1.5;
  // text-align: left;
  border: 10px solid #ef1413;
  min-height: 100vh;
  margin: 0;
  padding: 2rem;
  @media (max-width: 1000px) {
    font-size: 20px;
  }
  @media (max-width: 600px) {
    font-size: 18px;
  }
}
body {
  // background-color: white;
  // font-size: 16px;
  @media (max-width: 1000px) {
    font-size: 18px;
  }
}
.App {
  // text-align: center;
  // min-height: 100vh;
  // font-family: "Mono";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.layout {
  display: flex;
  align-items: flex-start;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

h1 {
  // margin: 0;
  // /* font-size: 3rem; */
  // /* line-height: 120%; */
  // font-size: 42px;
  // word-spacing: 0.25rem;
  // line-height: 54px;

  // padding-top: 0;
  // margin-top: 0;
  line-height: 1.2;
}

h1 span {
  font-family: "Anthony";
  // font-size: 41px;
  // color: #ff4d4d;
}

main {
  // flex: 5;
  // flex-direction: column;
  // text-align: left;
  // margin-right: 2rem;
  display: flex;
  margin-right: 2rem;
  flex-direction: column;

  @media (max-width: 1000px) {
    margin-right: 0;
  }
}

.canvas {
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
  height: calc(100vh - 4rem);
  /* border: 1px double black; */
  background-color: #fff;
  border-radius: 20px;
}

aside {
  position: inherit;
  flex: 3;
}

p {
  //   /* font-family: "Happy Times at the IKOB New Game Plus Edition"; */
  /* font-family: "Alegreya"; */
  // font-family: "Compagnon";
  //   font-size: 20px;
  //   // line-height: 2rem;
}

.rule {
  // margin-top: 2rem;
  // margin-bottom: 2rem;

  /* border-bottom: 1px dotted #000 */
  height: 2px;
  width: 100%;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='46' ry='46' stroke='%23333' stroke-width='5' stroke-dasharray='6%2c 14' stroke-dashoffset='58' stroke-linecap='round'/%3e%3c/svg%3e");
}
svg {
  fill: #333;
}

blockquote {
  font-family: "Compagnon";
  font-weight: 500;
  color: #ef1413;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  // margin-left: 0;
  margin-right: 0;

  p {
    margin-bottom: 0;

    span {
      font-family: "Mono";
      content: "“";
    }
  }

  h1 {
    color: black;
    margin-left: -1.5rem;
  }
}

cite {
  font-size: 18px;
  // margin-left: 1.5rem;
  font-family: "Steps-Mono";
  font-style: normal;
  margin-bottom: 0;
  margin-top: 0;
  // color: gray;
  font-weight: normal;
  text-transform: uppercase;
}

.divider {
  border-bottom: 2px black solid;
  display: inline-block;
  height: 2px;
  width: 100%;
}

.top {
  * {
    max-width: 95%;

    @media (max-width: 1000px) {
      max-width: 100%;
    }
  }
}

.bottom {
  // margin-top: 1rem;
  p {
    font-family: "Minipax";
    // font-weight: bold;
  }
  display: flex;
  // align-items: flex-end;
  flex-direction: column;
}

footer {
  font-size: 0.5rem;
  font-family: "Compagnon";
  font-weight: bold;
  margin-left: 2rem;
  // border-left: 0.2rem solid #000;
  // height: 100;
  flex: 1;

  // text-transform: uppercase;
  ul {
    list-style-type: none;
    text-align: right;
    li {
      margin-bottom: 0.25rem;
      font-size: 0.75rem;

      a {
        text-decoration: none;
        color: #ef1413;
      }
    }
  }
}

blockquote:before {
  line-height: 2.25rem;
  content: "“";
  position: absolute;
  margin-left: -1.5rem;
  font-size: 2rem;
  font-family: "Mono";
  height: fit-content;
}

h1 {
  margin-bottom: 0;
}

.bottom * {
  max-width: 70%;
  // text-align: right;

  @media (max-width: 1000px) {
    max-width: 100%;
  }
}

// .top {
//   max-width: 90%;
// }

.bottom p {
  font-size: 20px;
}

// .frame {
//   border: 10px solid #ef1413;
//   position: fixed;
//   top: 0;
//   right: 0;
//   left: 0;
//   bottom: 0;
// }

.top,
.bottom {
  flex: 1;
}

.image-fixed {
  position: fixed;
  display: flex;
  z-index: -10;
  top: 4rem;
  bottom: 4rem;
  left: 2rem;
  right: 2rem;
  align-items: center;
  justify-content: center;
  img {
    margin-bottom: 0;
    max-height: 100%;
  }
}

a {
  color: #ef1413;
}
